require('./src/css/global.scss');
const ReactDOM = require("react-dom/client");

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

function initScripts() {
  if (window.isScriptLoaded) {
    return false;
  }

  window.isScriptLoaded = true;

  if (window.location.href.includes('one.com/')) {
    return;
  }

  var beaconScript = document.createElement('script');
  beaconScript.type = 'text/javascript';
  beaconScript.innerHTML = `
    !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  `;
  document.head.appendChild(beaconScript);

  var helpScoutScript = document.createElement('script');
  helpScoutScript.type = 'text/javascript';
  helpScoutScript.innerHTML = `
    window.Beacon('init', "${process.env.HELPSCOUT_PROVIDER_KEY}");
  `;
  document.head.appendChild(helpScoutScript);
}

function loadScripts(event) {
  initScripts();
  event.currentTarget.removeEventListener(event.type, loadScripts);
}

exports.onClientEntry = function () {
  document.onreadystatechange = function () {
    if (document.readyState !== 'loading') {
      setTimeout(initScripts, 4000);
    }
  };

  document.addEventListener('scroll', loadScripts);
  document.addEventListener('mousemove', loadScripts);
  document.addEventListener('touchstart', loadScripts);
};
